import ApiService from "@/core/services/api.service";
import moment from "moment-timezone";
import ObjectPath from "object-path";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ManageSchedule from "@/core/plugins/job.plugin";
import { cloneDeep } from "lodash";
import {
  SET_CUSTOMER,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  SET_BILLING,
  SET_BILLING_CONTACT,
  RESET_STATE,
} from "@/core/services/store/visit.module";
import /*  CLEAR_LINE_ITEM, */
/*   SET_SELECTED_LINE_ITEM, */
/*   UPDATE_LINE_ITEM_STATE, */
"@/core/services/store/line.item.module";
import router from "@/router";
import { toSafeNumber } from "@/core/plugins/math.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

const todayDate = moment(new Date());

const formatDate = (date) => {
  if (moment(date).isValid()) {
    return moment(date).format("DD MMMM YYYY hh:mm A");
  }
};

const validScheduleType = ["daily", "weekly", "monthly"];

const adjustedStartTime = moment(todayDate)
  .startOf("hour")
  .add(Math.ceil(todayDate.minutes() / 60) * 60, "minutes");
const adjustedStartEnd = moment(todayDate)
  .add(1, "hour")
  .startOf("hour")
  .add(Math.ceil(todayDate.minutes() / 60) * 60, "minutes");

/* const initialSchedule = {
  start_date: moment(todayDate).format("YYYY-MM-DD"),
  end_date: moment(todayDate).format("YYYY-MM-DD"),
  start_time: adjustedStartTime.format("hh:mm A"),
  end_time: adjustedStartEnd.format("hh:mm A"),
}; */

const initialRecurringSchedule = {
  start_date: moment(todayDate).format("YYYY-MM-DD"),
  end_date: moment(todayDate).add(1, "day").format("YYYY-MM-DD"),
  start_time: adjustedStartTime.format("hh:mm A"),
  end_time: adjustedStartEnd.format("hh:mm A"),
};

// action types
export const CLEAR_PREVENTIVE = "JtxPreventiveClearTicket";
export const GET_PREVENTIVE = "JtxPreventiveGetTicket";
export const SET_PREVENTIVE = "JtxPreventiveSetTicket";
export const GET_OPTION = "JtxPreventiveGetOption";
export const SET_OPTION = "JtxPreventiveSetOption";
export const CREATE_OR_UPDATE = "JtxPreventiveCreateOrUpdate";
export const UPDATE_CALENDAR_ACTION = "JtxPreventiveUpdateCalendarAction";
export const UPDATE_SCHEDULE_STATE = "JtxPreventiveUpdateScheduleState";
export const UPDATE_SCHEDULE_ENGINEER = "JtxPreventiveUpdateScheduleEngineer";
/* export const GET_AVAILABLE_ENGINEER = "JtxPreventiveGetAvailableEngineer"; */
/* export const SET_AVAILABLE_ENGINEER = "JtxPreventiveSetAvailableEngineer"; */
export const REMOVE_ENGINEER = "JtxPreventiveRemoveEngineer";
/* export const RESET_ENGINEER = "JtxPreventiveResetEngineer"; */
export const SET_SCHEDULE_TYPE = "JtxPreventiveSetScheduleType";
export const GET_RECURRING_DATE = "JtxPreventiveGetRecurringDate";
export const SET_RECURRING_DATE = "JtxPreventiveSetRecurringDate";
export const SET_INITIAL_START_DATE = "SetInitialSdate";
export const SET_INITIAL_END_DATE = "SetInitialEdate";
export const SET_RAW_RECURRING_DATE = "JtxPreventiveSetRawRecurringDate";
export const SET_LOADING = "JtxPreventiveSetPreventiveLoading";
export const UPDATE_DEFAULT_DATE = "JtxPreventiveUpdateDefaultDate";

const dbPDurationList = [
  { id: 1, short_name: "1st", long_name: "First" },
  { id: 2, short_name: "2nd", long_name: "Second" },
  { id: 3, short_name: "3rd", long_name: "Third" },
  { id: 4, short_name: "4th", long_name: "Fourth" },
  { id: 5, short_name: "Last", long_name: "Last" },
];

const dbPWeekDays = [
  { id: 1, short_name: "Sun", long_name: "Sunday" },
  { id: 2, short_name: "Mon", long_name: "Monday" },
  { id: 3, short_name: "Tue", long_name: "Tuesday" },
  { id: 4, short_name: "Wed", long_name: "Wednesday" },
  { id: 5, short_name: "Thu", long_name: "Thursday" },
  { id: 6, short_name: "Fri", long_name: "Friday" },
  { id: 7, short_name: "Sat", long_name: "Saturday" },
];

const dbPRecurringScheduleTypeList = [
  { max_allowed: 0, group: "daily", text: "Daily", value: "daily" },
  { max_allowed: 1, group: "weekly", text: "Weekly", value: "weekly" },
  {
    max_allowed: 2,
    group: "weekly",
    text: "Twice a Week",
    value: "twice_a_week",
  },
  {
    max_allowed: 1,
    group: "weekly",
    text: "Fort-Nightly",
    value: "fort_nightly",
  },
  {
    max_allowed: 3,
    group: "weekly",
    text: "Thrice a Week",
    value: "thrice_a_week",
  },
  { max_allowed: 0, group: "monthly", text: "Monthly", value: "monthly" },
  {
    max_allowed: 0,
    group: "monthly",
    text: "Twice a Month",
    value: "twice_a_month",
  },
  {
    max_allowed: 0,
    group: "monthly",
    text: "BI-Monthly",
    value: "bi_monthly",
  },
  {
    max_allowed: 0,
    group: "monthly",
    text: "Quarterly",
    value: "quarterly",
  },
  {
    max_allowed: 0,
    group: "monthly",
    text: "BI-Annually",
    value: "bi_annually",
  },
  { max_allowed: 0, group: "monthly", text: "Annually", value: "annually" },
];

const dbPrevetiveScheduleTypeList = [
  {
    id: "one-off",
    value: "One-Off Job",
  },
  {
    id: "recurring",
    value: "Recurring Job",
  },
];

/* const dbOneOffSchedule = {
  start_date: initialSchedule.start_date,
  end_date: initialSchedule.end_date,
  start_time: initialSchedule.start_time ,
  end_time: initialSchedule.end_time ,
}; */

const dbPRecurringSchedule = {
  type: {
    group: "daily",
    text: "Daily",
    value: "daily",
  },
  force_update: false,
  weeks: [],
  occurrence: 1,
  week_day: 1,
  week_count: 1,
  second_week_day: 5,
  second_week_count: 1,
  recurring_pattern: 1,
  schedule_flag: 0,
  message: null,
  end_mode: 2 /* occurrences = 2; end-date = 3 */,
  start_date: initialRecurringSchedule.start_date /* FORMAT - YYYY-MM-DD*/,
  end_date: initialRecurringSchedule.end_date /* FORMAT - YYYY-MM-DD*/,
  start_time: initialRecurringSchedule.start_time /* FORMAT - hh:mm A*/,
  end_time: initialRecurringSchedule.end_time /* FORMAT - hh:mm A*/,
  dbEndDate: null,
  dbEndTime: null,
};

// mutation types
export default {
  state: {
    /*  dbDefaultStartedAt: null,
    dbDefaultFinishedAt: null, */
    dbPShowCalendar: false,
    dbPrventiveScheduleType: "one-off",
    /*   dbAvailableTeam: [],
    dbAssignedTeam: [],
    dbAssignedTeamObject: [], */
    dbPDurationList: cloneDeep(dbPDurationList),
    dbPWeekDays: cloneDeep(dbPWeekDays),
    dbPRecurringScheduleTypeList: cloneDeep(dbPRecurringScheduleTypeList),
    dbPrevetiveScheduleTypeList: cloneDeep(dbPrevetiveScheduleTypeList),
    /*    dbOneOffSchedule: cloneDeep(dbOneOffSchedule), */
    dbPRecurringSchedule: cloneDeep(dbPRecurringSchedule),
    preventiveDbScheduleOutput: [],
    dbPrventiveRawScheduleOutput: [],
    initialEndDate: null,
    initialStartDate: null,
    dbJobOption: {},
    dbPrventiveLoading: false,
    dbpreventiveEditData: {},
    visit_create_flag: true,
  },
  getters: {
    /*  defaultStartedAt(state) {
      return state.dbDefaultStartedAt;
    }, */
    /*  defaultFinishedAt(state) {
      return state.dbDefaultFinishedAt;
    }, */
    preventiveVisitCreateFlag(state) {
      return state.visit_create_flag;
    },
    preventiveEditData(state) {
      return state.dbpreventiveEditData;
    },
    preventiveOption(state) {
      return state.dbJobOption;
    },
    preventiveScheduleOutput(state) {
      return state.preventiveDbScheduleOutput;
    },
    initialStartDate(state) {
      return state.initialStartDate;
    },
    initialEndDate(state) {
      return state.initialEndDate;
    },
    prventiveRawScheduleOutput(state) {
      return state.dbPrventiveRawScheduleOutput;
    },
    prventiveScheduleType(state) {
      return state.dbPrventiveScheduleType;
    },
    prventiveLoading(state) {
      return state.dbPrventiveLoading;
    },
    /* oneOffSchedule(state) {
      return state.dbOneOffSchedule;
    }, */
    prventiveRecurringSchedule(state) {
      return state.dbPRecurringSchedule;
    },
    vPShowCalendar(state) {
      return state.dbPShowCalendar;
    },
    PWeekDays(state) {
      return state.dbPWeekDays;
    },
    PDurationList(state) {
      return state.dbPDurationList;
    },
    recurringPScheduleTypeList(state) {
      return state.dbPRecurringScheduleTypeList;
    },
    /*  assignedTeam(state) {
      return state.dbAssignedTeam;
    },
    availableTeam(state) {
      return state.dbAvailableTeam;
    },
    assignedTeamObject(state) {
      return state.dbAssignedTeamObject;
    }, */
    preventiveScheduleTypeList(state) {
      return state.dbPrevetiveScheduleTypeList;
    },
  },
  actions: {
    [CREATE_OR_UPDATE](context, payload) {
      return new Promise((resolve, reject) => {
        context.commit(SET_LOADING, true);
        let requestType = "post";
        let requestURL = "preventive-maintanance-visit";
        let preventiveId = payload?.preventive ?? 0;
        /* let visitId = payload?.visit ?? 0; */
        if (preventiveId) {
          requestType = "put";
          requestURL = `preventive-maintanance-visit/${preventiveId}`;
        }
        ApiService.setHeader();
        ApiService[requestType](requestURL, payload)
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            reject(false);
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      });
    },
    [GET_PREVENTIVE](context, { preventive }) {
      return new Promise((resolve, reject) => {
        context.commit(SET_LOADING, true);
        context.commit(SET_PREVENTIVE, {});
        ApiService.setHeader();
        ApiService.get(`preventive/${preventive}`)
          .then(({ data }) => {
            const schedule =
              data?.data?.daily ?? data?.data?.weekly ?? data?.data?.monthly;

            const startedAt = new Date(
              `${schedule?.start_date} ${schedule?.start_time}`
            );
            const finishedAt = new Date(
              `${schedule?.end_date} ${schedule?.end_time}`
            );

            context.commit(UPDATE_DEFAULT_DATE, { startedAt, finishedAt });

            const type = context.state.dbPRecurringScheduleTypeList.find(
              (row) => row.value == data?.data?.preventive?.btx_schedule_tab
            );

            context.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbPRecurringSchedule.type",
              value: type,
            });

            context.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbPRecurringSchedule.weeks",
              value: schedule?.weeks ?? [],
            });

            context.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbPRecurringSchedule.occurrence",
              value: toSafeNumber(schedule.occurrence, 1),
            });

            context.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbPRecurringSchedule.end_mode",
              value: toSafeNumber(schedule.end_mode, 1),
            });

            context.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbPRecurringSchedule.start_date",
              value: moment(startedAt).format("YYYY-MM-DD"),
            });

            context.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbPRecurringSchedule.end_date",
              value: moment(finishedAt).format("YYYY-MM-DD"),
            });

            context.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbPRecurringSchedule.start_time",
              value: moment(startedAt).format("hh:mm A"),
            });

            context.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbPRecurringSchedule.end_time",
              value: moment(finishedAt).format("hh:mm A"),
            });

            context.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbPRecurringSchedule.week_day",
              value: toSafeNumber(schedule.week_day, 1),
            });

            context.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbPRecurringSchedule.week_count",
              value: toSafeNumber(schedule.week_count, 1),
            });

            context.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbPRecurringSchedule.second_week_day",
              value: toSafeNumber(schedule.second_week_day, 1),
            });

            context.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbPRecurringSchedule.second_week_count",
              value: toSafeNumber(schedule.second_week_count, 1),
            });

            context.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbPRecurringSchedule.recurring_pattern",
              value: toSafeNumber(schedule.recurring_pattern, 1),
            });
            context.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbPRecurringSchedule.schedule_flag",
              value: toSafeNumber(schedule.schedule_flag, 1),
            });

            context.dispatch(GET_RECURRING_DATE);

            context.commit(SET_CUSTOMER, data?.data?.customer ?? {});
            context.commit(SET_PROPERTY, data?.data?.property ?? {});
            context.commit(
              SET_PROPERTY_CONTACT,
              data?.data?.propertycontactperson ?? {}
            );
            context.commit(SET_BILLING, data?.data?.billing ?? {});
            context.commit(
              SET_BILLING_CONTACT,
              data?.data?.billingcontactperson ?? {}
            );

            /*   context.commit(SET_AVAILABLE_ENGINEER, data?.data?.available_team ?? []); */

            /*     context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.subtotal",
              value: parseFloat(data?.data?.preventive?.sub_total ?? 0),
            });
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.discount_amount",
              value: parseFloat(data?.data?.preventive?.discount_amount ?? 0),
            });
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.discount_type",
              value: parseFloat(data?.data?.preventive?.discount_value_type ?? 1),
            });
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.discount_value",
              value: parseFloat(data?.data?.preventive?.discount_value ?? 0),
            });
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.tax_amount",
              value: parseFloat(data?.data?.preventive?.tax_amount ?? 0),
            });
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.tax_value",
              value: parseFloat(data?.data?.preventive?.tax_value ?? 0),
            });
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.tax_active",
              value: parseFloat(data?.data?.preventive?.tax_applied ?? 1),
            });
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.adjustment",
              value: parseFloat(data?.data?.preventive?.adjustment ?? 0),
            });
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.total",
              value: parseFloat(data?.data?.preventive?.total ?? 0),
            }); */

            /*    context.commit(SET_SELECTED_LINE_ITEM, data?.data?.line_item ?? {}); */
            context.commit(SET_PREVENTIVE, data?.data, {});

            resolve(true);
          })
          .catch(() => {
            context.commit(SET_PREVENTIVE, {});
            reject(false);
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      });
    },
    [GET_OPTION](context) {
      context.commit(SET_LOADING, true);
      context.commit(SET_OPTION, {});
      ApiService.setHeader();
      ApiService.query("preventive-maintanance/options", {
        ...router?.currentRoute?.query,
      })
        .then(({ data }) => {
          context.commit(SET_OPTION, data?.data ?? {});
        })
        .catch(() => {
          context.commit(SET_OPTION, {});
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    },
    /* [GET_AVAILABLE_ENGINEER](context, payload) {
      context.commit(SET_LOADING, true);
      context.commit(RESET_ENGINEER);
      ApiService.setHeader();
      ApiService.query("ticket/available-engineers", payload)
        .then(({ data }) => {
          context.commit(SET_AVAILABLE_ENGINEER, data?.data ?? []);
        })
        .catch(() => {
          context.commit(SET_AVAILABLE_ENGINEER, []);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    }, */
    [GET_RECURRING_DATE](context) {
      const prventiveRecurringSchedule = context.state.dbPRecurringSchedule;

      if (
        !validScheduleType.includes(prventiveRecurringSchedule?.type?.group)
      ) {
        return false;
      }

      const resetRecurringSchedule = () => {
        context.commit(UPDATE_SCHEDULE_STATE, {
          key: "dbPRecurringSchedule.message",
          value: null,
        });
        context.commit(UPDATE_SCHEDULE_STATE, {
          key: "dbPRecurringSchedule.end_date",
          value: prventiveRecurringSchedule.end_date,
        });
        context.commit(SET_RECURRING_DATE, []);
        context.commit(SET_RAW_RECURRING_DATE, []);
      };

      const startDate = moment(
        `${prventiveRecurringSchedule.start_date} ${prventiveRecurringSchedule.start_time}`
      ).format("YYYY-MM-DD HH:mm:ss");
      const occurrences =
        prventiveRecurringSchedule.end_mode == 2
          ? toSafeNumber(prventiveRecurringSchedule.occurrence)
          : 1000;
      const endDate =
        prventiveRecurringSchedule.end_mode == 3
          ? moment(
              `${prventiveRecurringSchedule.end_date} ${prventiveRecurringSchedule.end_time}`
            ).format("YYYY-MM-DD HH:mm:ss")
          : null;
      const selectedDays = prventiveRecurringSchedule.weeks;

      if (
        prventiveRecurringSchedule?.type?.group == "weekly" &&
        !selectedDays.length
      ) {
        resetRecurringSchedule();
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select weekday.")
        );
        return false;
      }

      const weekDay = toSafeNumber(prventiveRecurringSchedule.week_day, -1);
      const weekCount = toSafeNumber(prventiveRecurringSchedule.week_count, -1);
      const secondWeekDay = toSafeNumber(
        prventiveRecurringSchedule.second_week_day,
        -1
      );
      const secondWeekCount = toSafeNumber(
        prventiveRecurringSchedule.second_week_count,
        -1
      );
      const pattern = toSafeNumber(
        prventiveRecurringSchedule.recurring_pattern,
        1
      );
      const schedule_flag = toSafeNumber(
        prventiveRecurringSchedule.schedule_flag,
        0
      );
      const endMode = toSafeNumber(prventiveRecurringSchedule.end_mode, 2);

      const scheduleManager = new ManageSchedule(
        startDate,
        occurrences,
        endDate,
        endMode,
        pattern,
        weekDay,
        weekCount,
        secondWeekDay,
        secondWeekCount,
        schedule_flag
      );

      let scheduleDate = [];

      if (prventiveRecurringSchedule?.type?.value == "daily") {
        scheduleDate = scheduleManager.generateDailySchedule();
      }

      if (prventiveRecurringSchedule?.type?.group == "weekly") {
        if (prventiveRecurringSchedule?.type?.value == "weekly") {
          scheduleDate = scheduleManager.generateWeeklySchedule(
            1,
            selectedDays.slice(0, 1)
          );
        }

        if (prventiveRecurringSchedule?.type?.value == "twice_a_week") {
          scheduleDate = scheduleManager.generateWeeklySchedule(
            1,
            selectedDays.slice(0, 2)
          );
        }

        if (prventiveRecurringSchedule?.type?.value == "fort_nightly") {
          scheduleDate = scheduleManager.generateWeeklySchedule(
            2,
            selectedDays.slice(0, 1)
          );
        }

        if (prventiveRecurringSchedule?.type?.value == "thrice_a_week") {
          scheduleDate = scheduleManager.generateWeeklySchedule(
            1,
            selectedDays.slice(0, 3)
          );
        }
      }

      if (prventiveRecurringSchedule?.type?.group == "monthly") {
        if (prventiveRecurringSchedule?.type?.value == "monthly") {
          scheduleDate = scheduleManager.generateMonthlySchedule(1);
        }

        if (prventiveRecurringSchedule?.type?.value == "twice_a_month") {
          scheduleDate = scheduleManager.generateTwiceAMonthlySchedule();
        }

        if (prventiveRecurringSchedule?.type?.value == "bi_monthly") {
          scheduleDate = scheduleManager.generateMonthlySchedule(2);
        }

        if (prventiveRecurringSchedule?.type?.value == "quarterly") {
          scheduleDate = scheduleManager.generateMonthlySchedule(3);
        }

        if (prventiveRecurringSchedule?.type?.value == "bi_annually") {
          scheduleDate = scheduleManager.generateMonthlySchedule(6);
        }

        if (prventiveRecurringSchedule?.type?.value == "annually") {
          scheduleDate = scheduleManager.generateMonthlySchedule(12);
        }
      }

      if (!scheduleDate.length) {
        resetRecurringSchedule();
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(
            "Oops... Dates are not available, please select valid schedule."
          )
        );
        return false;
      }

      const scheduleStartDate = scheduleDate.length ? scheduleDate[0] : null;
      const scheduleEndDate = scheduleDate.length
        ? new Date(scheduleDate[scheduleDate.length - 1])
        : null;

      const endTime = moment(
        `${prventiveRecurringSchedule.start_date} ${prventiveRecurringSchedule.end_time}`
      ).format("HH:mm:ss");

      let [hour, minute, second] = endTime.split(":");

      if (scheduleEndDate instanceof Date) {
        scheduleEndDate.setHours(hour);
        scheduleEndDate.setMinutes(minute);
        scheduleEndDate.setSeconds(second);
      }

      const message = String(scheduleManager.message)
        .replace("{TYPE}", prventiveRecurringSchedule?.type?.text)
        .replace("{START_DATE_TIME}", formatDate(scheduleStartDate))
        .replace("{OCCURRENCE}", scheduleDate.length)
        .replace("{END_DATE_TIME}", formatDate(scheduleEndDate));

      context.commit(UPDATE_SCHEDULE_STATE, {
        key: "dbPRecurringSchedule.message",
        value: message,
      });
      context.commit(UPDATE_SCHEDULE_STATE, {
        key: "dbPRecurringSchedule.dbEndDate",
        value: moment(scheduleEndDate).format("YYYY-MM-DD"),
      });
      /*    context.commit(UPDATE_SCHEDULE_STATE, {
        key: "dbPRecurringSchedule.end_date",
        value: moment(scheduleEndDate).format("YYYY-MM-DD"),
      });  */

      const combineDate = scheduleManager.combineDate(scheduleDate, endTime);
      context.commit(SET_RECURRING_DATE, combineDate);
      context.commit(SET_RAW_RECURRING_DATE, combineDate);
    },
    [CLEAR_PREVENTIVE](context) {
      context.commit(CLEAR_PREVENTIVE);
      context.commit(RESET_STATE);
      /*  context.commit(CLEAR_LINE_ITEM); */
    },
    [UPDATE_DEFAULT_DATE](context, payload) {
      let startedAt = null;
      let finishedAt = null;

      /*  if (payload == 'one-off') {
        startedAt = `${context.state.dbOneOffSchedule.start_date} ${context.state.dbOneOffSchedule.start_time}`;
        finishedAt = `${context.state.dbOneOffSchedule.end_date} ${context.state.dbOneOffSchedule.end_time}`;
      } */

      if (context.state.dbpreventiveEditData?.ticket?.id) {
        return false;
      }

      if (payload == "recurring") {
        startedAt = `${context.state.dbPRecurringSchedule.start_date} ${context.state.dbPRecurringSchedule.start_time}`;
        finishedAt = `${context.state.dbPRecurringSchedule.end_date} ${context.state.dbPRecurringSchedule.end_time}`;
      }

      context.commit(UPDATE_DEFAULT_DATE, { startedAt, finishedAt });
    },
  },
  mutations: {
    [CLEAR_PREVENTIVE](state) {
      /*    state.dbDefaultStartedAt = null;
      state.dbDefaultFinishedAt = null; */
      state.dbPShowCalendar = false;
      state.dbPrventiveScheduleType = "one-off";
      /*    state.dbAvailableTeam = [];
      state.dbAssignedTeam = [];
      state.dbAssignedTeamObject = []; */
      state.dbPDurationList = cloneDeep(dbPDurationList);
      state.dbPWeekDays = cloneDeep(dbPWeekDays);
      state.dbPRecurringScheduleTypeList = cloneDeep(
        dbPRecurringScheduleTypeList
      );
      state.dbPrevetiveScheduleTypeList = cloneDeep(
        dbPrevetiveScheduleTypeList
      );
      /*   state.dbOneOffSchedule = cloneDeep(dbOneOffSchedule); */
      state.dbPRecurringSchedule = cloneDeep(dbPRecurringSchedule);
      state.preventiveDbScheduleOutput = [];
      state.dbPrventiveRawScheduleOutput = [];
      state.initialStartDate = null;
      state.initialEndDate = null;
      state.dbPrventiveRawScheduleOutput = [];
      state.dbJobOption = {};
      state.dbPrventiveLoading = false;
      state.dbpreventiveEditData = {};
    },
    [SET_PREVENTIVE](state, payload) {
      state.dbpreventiveEditData = cloneDeep(payload);
    },
    [UPDATE_DEFAULT_DATE](state, { startedAt, finishedAt }) {
      state.dbDefaultStartedAt = startedAt
        ? moment(startedAt).format("YYYY-MM-DD HH:mm")
        : null;
      state.dbDefaultFinishedAt = finishedAt
        ? moment(finishedAt).format("YYYY-MM-DD HH:mm")
        : null;
    },
    [SET_OPTION](state, payload) {
      state.dbJobOption = cloneDeep(payload);
    },
    [SET_RECURRING_DATE](state, payload) {
      state.preventiveDbScheduleOutput = cloneDeep(payload);
    },
    [SET_INITIAL_START_DATE](state, payload) {
      state.initialStartDate = payload;
    },
    [SET_INITIAL_END_DATE](state, payload) {
      state.initialEndDate = payload;
    },
    [SET_RAW_RECURRING_DATE](state, payload) {
      if (
        !state.initialStartDate &&
        !state.initialEndDate &&
        payload &&
        payload.length &&
        payload.length > 1
      ) {
        state.initialStartDate = payload[0].startDate;
        state.initialEndDate = payload[1].endDate;
      }
      state.dbPrventiveRawScheduleOutput = cloneDeep(payload);
    },
    /*  [RESET_ENGINEER](state) {
      state.dbAssignedTeam = [];
      state.dbAvailableTeam = [];
      state.dbAssignedTeamObject = [];
    },
    [REMOVE_ENGINEER](state, payload) {
      state.dbAssignedTeam = cloneDeep(
        state.dbAssignedTeam.filter((obj) => payload != obj)
      );
      state.dbAssignedTeamObject = cloneDeep(
        state.dbAssignedTeamObject.filter((obj) => payload != obj.engineer_id)
      );
    },
    [UPDATE_SCHEDULE_ENGINEER](state) {
      let dbAvailableTeam = cloneDeep(state.dbAvailableTeam);
      let dbAssignedTeam = cloneDeep(state.dbAssignedTeam);
      let objectAssignedTeam = [];
      for (let i = 0; i < dbAssignedTeam.length; i++) {
        let exists = find(dbAvailableTeam, { engineer_id: dbAssignedTeam[i] });
        if (exists) {
          objectAssignedTeam.push(exists);
        }
      }
      state.dbAssignedTeamObject = cloneDeep(objectAssignedTeam);
    }, */
    [SET_LOADING](state, payload) {
      state.dbPrventiveLoading = payload;
    },
    [SET_SCHEDULE_TYPE](state, payload) {
      state.dbPrventiveScheduleType = payload;
    },
    /*  [SET_AVAILABLE_ENGINEER](state, payload) {
    [UPDATE_SCHEDULE_ENGINEER](state) {
      let dbAvailableTeam = cloneDeep(state.dbAvailableTeam);
      let dbAssignedTeam = cloneDeep(state.dbAssignedTeam);
      let objectAssignedTeam = [];
      for (let i = 0; i < dbAssignedTeam.length; i++) {
        let exists = find(dbAvailableTeam, { engineer_id: dbAssignedTeam[i] });
        if (exists) {
          objectAssignedTeam.push(exists);
        }
      }
      state.dbAssignedTeamObject = cloneDeep(objectAssignedTeam);
    }, */
    [SET_SCHEDULE_TYPE](state, payload) {
      state.dbPrventiveScheduleType = payload;
    },
    /*  [SET_AVAILABLE_ENGINEER](state, payload) {
    [UPDATE_SCHEDULE_ENGINEER](state) {
      let dbAvailableTeam = cloneDeep(state.dbAvailableTeam);
      let dbAssignedTeam = cloneDeep(state.dbAssignedTeam);
      let objectAssignedTeam = [];
      for (let i = 0; i < dbAssignedTeam.length; i++) {
        let exists = find(dbAvailableTeam, { engineer_id: dbAssignedTeam[i] });
        if (exists) {
          objectAssignedTeam.push(exists);
        }
      }
      state.dbAssignedTeamObject = cloneDeep(objectAssignedTeam);
    }, */
    [SET_SCHEDULE_TYPE](state, payload) {
      state.dbPrventiveScheduleType = payload;
    },
    /*  [SET_AVAILABLE_ENGINEER](state, payload) {
      state.dbAvailableTeam = cloneDeep(payload);
      if (!payload.length) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Oops.. No Engineers are available.")
        );
      }
    }, */
    [UPDATE_CALENDAR_ACTION](state) {
      state.dbPShowCalendar = !state.dbPShowCalendar;
    },
    [UPDATE_SCHEDULE_STATE](state, { key, value }) {
      ObjectPath.set(state, key, cloneDeep(value));
    },
  },
};
